import loadingAnimation from "@/assets/lottie/LoadingLine.json";
import { colors } from "@/styles/global.styles";
import { isMobile } from "@/utils/helpers/Utils";
import { css } from "@emotion/react";
import Lottie from "lottie-react";
import { ReactNode } from "react";

const LoadingAnimation = ({
  title,
  children = null,
}: {
  title: string;
  children?: ReactNode;
}) => {
  const commonStyles = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  `;

  const mobileStyles = css`
    ${commonStyles};
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0);
    z-index: 10;
  `;

  const desktopStyles = css`
    ${commonStyles};
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    color: ${colors.primaryTextColor};
  `;

  const lottieStyles = css`
    height: ${isMobile() ? "17vh" : "10vh"};
    position: relative;
    margin-bottom: -2vh;
  `;

  return (
    <div css={isMobile() ? mobileStyles : desktopStyles}>
      <div>
        <Lottie
          id="loadingAnimation"
          css={lottieStyles}
          animationData={loadingAnimation}
          loop
        />
      </div>
      <div
        css={css`
          font-size: ${isMobile() ? "1rem" : "1em"};
          ${isMobile() &&
          `
            @media (orientation: landscape) {
              padding-top: 1em;
            }
          `}
        `}
      >
        {title}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
