import {
  getAppButtonStyles,
  qrCodeImageStyles,
} from "../odience/pages/eventList/components/EventListHeader";

const GetAppButton = ({ onGetAppModal }: { onGetAppModal: () => void }) => (
  <button onClick={onGetAppModal} css={getAppButtonStyles} type={"button"}>
    <img
      src="/odience/web_client/Qr-code.svg"
      alt="QR Code"
      css={qrCodeImageStyles}
    />
    <div>Get the app</div>
  </button>
);

export default GetAppButton;
