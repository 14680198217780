import OdienceProfileAvatar from "@/components/odience/components/OdienceProfileAvatar";
import GetAppButton from "@/components/shared/GetAppButton";
import LoginButton from "@/components/shared/LoginButton";
import { paths } from "@/routerPaths";
import { odienceColors } from "@/styles/global.styles";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { ss } from "@/utils/helpers/sessionStorage";
import { useOdienceOrganization } from "@/utils/hooks/useOdienceOrganization";
import { css } from "@emotion/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

type EventListHeaderProps = {
  handleGetAppModal: () => void;
  odienceAvatar: string;
  handleOdienceAvatarError: () => void;
  handleOpenEditProfile: () => void;
  organizationTitle?: string;
  onShowProvisioning: VoidFunction;
};

const BackToOnboarding = () => {
  const handleRemoveInviteStorageItems = () => {
    ss.removeEmail();
    ss.removeInvite();
    ss.removeInvitedEventId();
    ss.removeLastEventId();
    ss.removeLastGroupId();
  };

  return (
    <div css={backToOnboardingStyles}>
      <Link
        to={paths.onboarding}
        css={linkStyles}
        onClick={handleRemoveInviteStorageItems}
      >
        <ExpandMoreIcon css={expandMoreIconStyles} />
        <div>Verse Messages</div>
      </Link>
    </div>
  );
};

const EventListHeader = ({
  handleGetAppModal,
  odienceAvatar,
  handleOdienceAvatarError,
  handleOpenEditProfile,
  organizationTitle,
  onShowProvisioning,
}: EventListHeaderProps) => {
  const { embeddedMode } = useOdienceOrganization();
  return (
    <div>
      {!isProvisioned() && !embeddedMode && <BackToOnboarding />}

      <div css={containerStyles}>
        {organizationTitle ? (
          <div
            css={headerTitleStyles}
            style={{
              letterSpacing: "initial",
              wordBreak: "break-word",
              textAlign: "left",
              overflow: "hidden",
              width: "60%",
            }}
          >
            {organizationTitle}
          </div>
        ) : (
          <div css={headerTitleStyles}>ODIENCE</div>
        )}

        <div css={rightSectionStyles}>
          <GetAppButton onGetAppModal={handleGetAppModal} />
          {!isProvisioned() ? (
            <LoginButton onShowProvisioning={onShowProvisioning} />
          ) : (
            <OdienceProfileAvatar
              {...{
                odienceAvatar,
                handleOdienceAvatarError,
                handleOpenEditProfile,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EventListHeader;

const backToOnboardingStyles = css({
  color: odienceColors.pureWhite,
  paddingBottom: "2.25em",
});

const linkStyles = css({
  display: "flex",
  gap: "0.5vw",
  flexDirection: "row",
  alignItems: "center",
});

const expandMoreIconStyles = css({
  transform: "rotate(90deg)",
  width: "1.25em",
  height: "1.25em",
  fontWeight: "bold",
});

const headerTitleStyles = css({
  color: odienceColors.pureWhite,
  fontWeight: "lighter",
  textAlign: "center",
  letterSpacing: "1.875rem",
  fontSize: "1.875rem",
});

export const getAppButtonStyles = css({
  display: "flex",
  backgroundColor: odienceColors.pureBlack,
  color: "white",
  gap: "0.5em",
  border: `3px solid ${odienceColors.mutedGray}`,
  borderRadius: "10px",
  fontWeight: "bold",
  textDecoration: "none",
  padding: "2vh 1vw",
});

export const qrCodeImageStyles = css({
  height: "1.5em",
});

const containerStyles = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const rightSectionStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "1vw",
  marginRight: "2em",
});
