import { colors } from "@/styles/global.styles";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

const loginButtonStyles = css({
  textDecoration: "none",
  border: "none",
  borderRadius: "10px",
  padding: "2vh 2vw",
  color: colors.primaryTextColor,
  fontWeight: "bold",
  backgroundColor: colors.primaryAccentColor,
  fontSize: "1.125rem",
});

const LoginButton = ({
  onShowProvisioning,
}: {
  onShowProvisioning: VoidFunction;
}) => {
  return (
    <div>
      <Link css={loginButtonStyles} to={"#"} onClick={onShowProvisioning}>
        Log in
      </Link>
    </div>
  );
};

export default LoginButton;
